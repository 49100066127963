import React from "react"
import * as style from "./vhis.module.scss"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../plugins/common"
import Header from "../components/vhis/Header"

const BigPoint = loadable(() => import("../components/vhis/BigPoint"))
const Advantages = loadable(() => import("../components/vhis/Advantages"))
const ComparisonTable = loadable(() =>
  import("../components/vhis/ComparisonTable")
)
const Partners = loadable(() => import("../components/vhis/Partners"))
const BigPointCompare = loadable(() =>
  import("../components/vhis/BigPointCompare")
)
const IconTextBanner = loadable(() =>
  import("../components/homepageV2/IconTextBanner")
)

const VhisPage = ({ pageContext, location }) => {
  const { data, form, general } = pageContext
  const { header, dynamicBody } = data
  const aleaToken = general.aleaToken
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(header.image)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  const getBgColor = color => (color === "sand" ? "bg-beige" : "bg-white")
  // console.log("data: ", data)

  return (
    <Layout
      props={pageContext.general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <Header data={header} lang={lang} />
      <div className={`${style.vhisBody} mt-5`}>
        {dynamicBody.map((section, index) => (
          <div
            key={index}
            className={`${style.section} ${
              section.bgColor ? getBgColor(section.bgColor) : ""
            }`}
          >
            {(section.strapi_component === "layout.title-image-rich-ctas" && (
              <BigPoint data={section} lang={lang} />
            )) ||
              (section.strapi_component ===
                "layout.title-image-desc-groups" && (
                <Advantages data={section} lang={lang} />
              )) ||
              (section.strapi_component === "layout.comparison-table" && (
                <ComparisonTable data={section} lang={lang} />
              )) ||
              (section.strapi_component === "vhis.partner-section" && (
                <Partners data={section} lang={lang} />
              )) ||
              (section.strapi_component === "layout.big-point-compare" && (
                <BigPointCompare data={section} lang={lang} />
              )) ||
              (section.strapi_component === "homepage.horizontal-cta" && (
                <IconTextBanner data={section} lang={lang} isFull={true} />
              ))}
          </div>
        ))}
      </div>
    </Layout>
  )
}
export default VhisPage
